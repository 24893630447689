<template>
  <div>
    <div class="d-block">
      <div :class="(orientation === 'vertical')?'flex-column':'flex-row'">
        <div class="head-col">

          <div
            class="d-flex  h-100"
            style="min-height: 70px;"
            :class="$store.getters['layout/getInnerWidth'] <= 900 ? 'flex-column' : 'items-align-center'"
          >
            <d-button
              class="ml-2 d-btn btn d-btn-default"
              :class="$store.getters['layout/getInnerWidth'] <= 900 ? 'p-1 pl-3 pr-3 mb-1' : ''"
              :icon="$store.getters['layout/getInnerWidth'] <= 900 ? 'fa fa-minus' : 'fa fa-minus mr-1'"
              @on:button-click="minusSize()"
            />
            <d-button
              class="ml-2 d-btn btn d-btn-default"
              :class="$store.getters['layout/getInnerWidth'] <= 900 ? 'p-1 pl-3 pr-3' : ''"
              :icon="$store.getters['layout/getInnerWidth'] <= 900 ? 'fa fa-plus' : 'fa fa-plus mr-1'"
              @on:button-click="plusSize()"
            />
          </div>
        </div>
        <template v-for="(hour, i) in hours">
          <div
            v-if="parseInt(i) !== hours.length - 1"
            :key="'hour-' + i"
            class="time-block"
            :style="
                  (orientation === 'vertical')?
                  {'min-height': (60 * size) + 'px', 'max-height': (60 * size) + 'px'}:
                  {'min-width': (120 * size) + 'px', 'max-width': (120 * size) + 'px'}"
          >
            <label>
              {{ hour.format('HH:mm') }}
            </label>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orientation: {
      type: String,
      default: () => 'vertical'
    },
    hours: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      default: () => 1,
    },
    blocks: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    minusSize() {
      this.size = this.size - 0.3;
      if (this.size < 1) {
        this.size = 1;
      }
      this.$emit('on:change:size', this.size);
    },
    plusSize() {
      this.size = this.size + 0.3;
      this.$emit('on:change:size', this.size);
    },
    scrollToRight() {
      this.$emit('on:scroll:planning-right');
    },
    scrollToLeft() {
      this.$emit('on:scroll:planning-left');
    },
  }
}
</script>
<style scoped>
.items-align-center {
  align-items: center;
}

.block-title {
  font: normal normal normal 14px Helvetica;
  letter-spacing: 0.24px;
  color: #4D4F5C;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1pt solid white;
}

.d-block .flex-column {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.d-block .flex-row {
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  overflow: hidden;
}

.d-block .flex-column .time-block {
  width: 100%;
  text-align: center;
}

.d-block .flex-row .time-block {
  font-size: 1rem;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.d-block label {
  text-align: center;
  width: 100%;
}

.time-block:nth-child(odd) {
  background-color: rgba(230, 230, 230, 0.3);
}

.time-block:last-child {
  background-color: white;
}

.d-block .flex-row .head-col {
  min-width: 80px;
  max-width: 80px;
  background-color: rgba(0, 0, 0, 0);
}

.d-block .flex-column .head-col {
  min-height: 70px;
  max-height: 70px;
  min-width: 80px;
  max-width: 80px;
  background-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 900px) {
  .d-block .flex-column .head-col {
    min-width: 60px;
    max-width: 60px;
  }
}

.d-block {
  display: block;
  background-color: white;
}

.d-inline {
  display: inline-block;
}

.btn-arrow-planning {
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-weight: 900;
  width: 15px;
  margin-top: 5pt;
}

.btn-arrow-planning i {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.6rem;
  color: #424242;
}

/deep/ .d-btn-default {
  padding: 10px;
  margin-left: 0 !important;
  margin-right: 2px !important;
  align-items: center;
}
</style>
